import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Running a service";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "running-a-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#running-a-service",
        "aria-label": "running a service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running a service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-declare-an-empty-service"
        }}>{`1. Declare an empty service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-add-a-service-to-a-server"
        }}>{`2. Add a service to a server`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-run-the-server-and-service"
        }}>{`3. Run the server and service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-test-connecting-to-the-server"
        }}>{`4. Test connecting to the server`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#whats-next"
        }}>{`What's next`}</a></li>
    </ul>
    <p>{`In this step, we'll do three things with the code we obtained from our proto file; we'll create a server instance, add an empty gRPC service, and then lastly test connecting to the server.`}</p>
    <TutorialSteps current={2} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the `}<a parentName="p" {...{
        "href": "/tutorials/grpc/blog/define-service#6-compile-the-proto-file"
      }}>{`generated Java code`}</a>{` obtained from the previous step.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/grpc"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <h2 {...{
      "id": "1-declare-an-empty-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-declare-an-empty-service",
        "aria-label": "1 declare an empty service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Declare an empty service`}</h2>
    <p>{`Create a file `}<inlineCode parentName="p">{`BlogService.java`}</inlineCode>{` and declare an empty blog service. We'll implement the service methods later on in this file. For now, leave it empty.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java",
        "filename": "BlogService.java"
      }}>{`package example.armeria.blog.grpc;

final class BlogService extends BlogServiceGrpc.BlogServiceImplBase {}
`}</code></pre>
    <h2 {...{
      "id": "2-add-a-service-to-a-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-add-a-service-to-a-server",
        "aria-label": "2 add a service to a server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Add a service to a server`}</h2>
    <p>{`Build a service and server using Armeria's `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{` to serve our service.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a main class for our server. You can see the full version of the file `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria-examples/blob/main/tutorials/grpc/src/main/java/example/armeria/server/blog/grpc/Main.java"
          }}>{`here`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`package example.armeria.blog.grpc;

import org.slf4j.Logger;
import org.slf4j.LoggerFactory;

public final class Main {
  private static final Logger logger = LoggerFactory.getLogger(Main.class);
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a service instance using Armeria's `}<a parentName="p" {...{
            "href": "type://GrpcService#builder():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html#builder()"
          }}>{`type://GrpcService#builder()`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`import com.linecorp.armeria.server.grpc.GrpcService;
import com.linecorp.armeria.server.Server;

public final class Main {
...
  static Server newServer(int port) throws Exception {
    final GrpcService grpcService =
      GrpcService.builder()
                 .addService(new BlogService())
                 .build();
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Build and return a new server instance using Armeria's `}<a parentName="p" {...{
            "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
          }}>{`type://ServerBuilder`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`  public final class Main {
    static Server newServer(int port) throws Exception {
      ...
      return Server.builder()
                   .http(port)
                   .service(grpcService)
                   .build();

    }
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "3-run-the-server-and-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-run-the-server-and-service",
        "aria-label": "3 run the server and service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Run the server and service`}</h2>
    <p>{`Create a server instance and run the blog service.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a server instance in the `}<inlineCode parentName="p">{`main()`}</inlineCode>{` method.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`public static void main(String[] args) throws Exception {
    final Server server = newServer(8080);

    server.closeOnJvmShutdown().thenRun(() -> {
        logger.info("Server has been stopped.");
    });

    server.start().join();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Start the server by running the `}<inlineCode parentName="p">{`Main.main()`}</inlineCode>{` method on your IDE or using Gradle.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`./gradlew run
`}</code></pre>
        <p parentName="li">{`Your server is running if you see the following message.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`[armeria-boss-http-*:8080] INFO com.linecorp.armeria.server.Server - Serving HTTP at /[0:0:0:0:0:0:0:0%0]:8080 - http://127.0.0.1:8080/
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "4-test-connecting-to-the-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-test-connecting-to-the-server",
        "aria-label": "4 test connecting to the server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Test connecting to the server`}</h2>
    <p>{`Let's create test code and connect to the server by sending a request using a client stub.
Note that we'll use test code to verify what we implement along the way.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a file, `}<inlineCode parentName="p">{`BlogServiceTest.java`}</inlineCode>{`, under `}<inlineCode parentName="p">{`{project_root}/src/test/java/example/armeria/blog/grpc`}</inlineCode>{` as follows.
You can see the full version of the file `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria-examples/blob/main/tutorials/grpc/src/test/java/example/armeria/server/blog/grpc/BlogServiceTest.java"
          }}>{`here`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`package example.armeria.blog.grpc;
import example.armeria.blog.grpc.BlogServiceGrpc.BlogServiceBlockingStub;

class BlogServiceTest {
  static BlogServiceBlockingStub client;
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, add a test method as follows.
Although we haven't implemented any service methods, we'll call one just to check whether we can connect to the server.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`import org.junit.jupiter.api.Test;
import com.linecorp.armeria.client.grpc.GrpcClients;

@Test
void connect() {
  client = GrpcClients.newClient("http://127.0.0.1:8080/",
                                 BlogServiceBlockingStub.class);
  client.createBlogPost(CreateBlogPostRequest.newBuilder().build());
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Make sure that your server is running.
If you have not stopped the server, it should already be running.
Otherwise, restart the server by running the `}<inlineCode parentName="p">{`Main.main()`}</inlineCode>{` method.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run the test case on your IDE or using Gradle.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`./gradlew test
`}</code></pre>
        <p parentName="li">{`Your client sent a request to the service successfully if the test fails and throws an "UNIMPLEMENTED" exception.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`UNIMPLEMENTED: Method example.armeria.blog.grpc.BlogService/CreateBlogPost is unimplemented
`}</code></pre>
        <p parentName="li">{`This is because we are yet to implement the service method to create a blog post. One thing we did check is that our service does return something to a client call.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've created and added an empty gRPC service to a server. We've also written a test and made a call to the server.`}</p>
    <p>{`Next, we'll get on with implementing a service method for `}<a parentName="p" {...{
        "href": "/tutorials/grpc/blog/implement-create"
      }}>{`creating blog posts`}</a>{`.`}</p>
    <TutorialSteps current={2} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      